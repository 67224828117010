import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import PolicyCopy from "./features/policy-copy/PolicyCopy";
import PrivateRoute from "./components/PrivateRoute";
import { Role } from "./types";
import SimpleLoader from "./components/SimpleLoader";

const AddPortfolio = lazy(() => import("./features/portfolio/AddPortfolio"));
const CreateTradingCase = lazy(() => import("./features/trading/create-trading-case/CreateTradingCaseContextProvider"));
const AuthorizationCallback = lazy(() => import("./features/authorization-callback/AuthorizationCallback"));
const BillingExport = lazy(() => import("./features/billing-export/BillingExport"));
const Carrier = lazy(() => import("./features/carrier/CarrierContextProvider"));
const CarrierRequests = lazy(() => import("./features/carrier-requests/CarrierRequestContextProvider"));
const ClientDetails = lazy(() => import("./features/client/ClientPage"));
const ClientPolicyUploadQueue = lazy(() => import("./features/client-policy-upload-queue/ClientPolicyUploadQueue"));
const ClientSearch = lazy(() => import("./features/client-search/ClientSearch"));
const ComparisonGenerator = lazy(() => import("./features/comparison-generator/ComparisonGenerator"));
const ContactSearch = lazy(() => import("./features/contact-search/ContactSearch"));
const CreateClient = lazy(() => import("./features/create-client/CreateClient"));
const DeathClaims = lazy(() => import("./features/death-claims-queue/DeathClaimsQueue"));
const DocumentManagement = lazy(() => import("./features/document-management/DocumentManagementContextProvider"));
const Decrypt = lazy(() => import("./features/encryption-services/Decrypt"));
const ExternalLEQueue = lazy(() => import("./features/external-le-queue/ExternalLEQueue"));
const Fallback = lazy(() => import("./features/fallback/Fallback"));
const FaxInbox = lazy(() => import("./features/fax-inbox/FaxInbox"));
const GraceQueue = lazy(() => import("./features/grace-queue/GraceQueue"));
const Insured = lazy(() => import("./features/insured/InsuredContextProvider"));
const InsuredSearch = lazy(() => import("./features/insured-search/InsuredSearch"));
const InsuredsNearMaturity = lazy(() => import("./features/insureds-near-maturity/InsuredsNearMaturity"));
const LongevityAnalytics = lazy(() => import("./features/longevity-analytics/LongevityAnalytics"));
const LongevityAnalyticsUser = lazy(() => import("./features/user/LongevityAnalyticsUser"));
const LongevityAnalyticsUserSearch = lazy(() => import("./features/user-search/LongevityAnalyticsUserSearch"));
const MachineUser = lazy(() => import("./features/machine-user/MachineUser"));
const MailMerge = lazy(() => import("./features/mail-merge/MailMergeContextProvider"));
const MassUpload = lazy(() => import("./features/bulk-premium-upload/MassUpload"));
const MassUploadDetails = lazy(() => import("./features/bulk-premium-upload/MassUploadDetails"));
const MedrecQueue = lazy(() => import("./features/medrec-queue/MedrecQueue"));
const OptQueue = lazy(() => import("./features/policy-opt-queue/PolicyOptQueue"));
const PaymentHolds = lazy(() => import("./features/payment-holds/PaymentHolds"));
const Policy = lazy(() => import("./features/policy/PolicyContextProvider"));
const PolicyQueue = lazy(() => import("./features/policy-queue/PolicyQueueContextProvider"));
const PolicySearch = lazy(() => import("./features/policy-search/PolicySearch"));
const PolicyValuation = lazy(() => import("./features/policy-valuation/PolicyValuationContextProvider"));
const Portfolio = lazy(() => import("./features/portfolio/Portfolio"));
const PortfolioSearch = lazy(() => import("./features/portfolio-search/PortfolioSearch"));
const PortfolioUpload = lazy(() => import("./features/portfolio-upload/PortfolioUploadContextProvider"));
const ProductLibrary = lazy(() => import("./features/product-library/ProductLibraryContextProvider"));
const ReportGenerator = lazy(() => import("./features/report-generator/ReportGeneratorContextProvider"));
const SecureFileTransfer = lazy(() => import("./features/secure-file-transfer/SecureFileTransfer"));
const SpecialAuthFiles = lazy(() => import("./features/special-auth-files/SpecialAuthFiles"));
const TermWholeLife = lazy(() => import("./features/term-whole-life-queue/TermWholeLifeQueue"));
const TrackingPage = lazy(() => import("./features/tracking-queue/TrackingPage/TrackingPage"));
const TrackingQueue = lazy(() => import("./features/tracking-queue/TrackingQueue"));
const TradingCompanyList = lazy(() => import("./features/trading/trading-company/TradingCompanyList"));
const TradingCompany = lazy(() => import("./features/trading/trading-company/TradingCompanyDetail"));
const TradingQueue = lazy(() => import("./features/trading/trading-queue/TradingQueueContextProvider"));
const User = lazy(() => import("./features/user/User"));
const UserPermissions = lazy(() => import("./features/user-permissions/UserPermissions"));
const UserSearch = lazy(() => import("./features/user-search/UserSearch"));
const ValidationQueue = lazy(() => import("./features/validation-queue"));
const WireInstructions = lazy(() => import("./features/wire-instructions/WireInstructions"));

const AppRoutes: React.FC = () => {
    return (
        <Suspense fallback={<SimpleLoader />}>
            <Switch>
                <Route path="/callback" component={AuthorizationCallback} />
                <Route path="/fallback" component={Fallback} />
                <PrivateRoute path="/add-portfolio" exact component={AddPortfolio} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute
                    path="/billing-export"
                    component={BillingExport}
                    allowIfHasAnyRole={[Role.Accounting, Role.AccountingAdmin]}
                />
                <PrivateRoute path="/create-trading-case/:caseID?" component={CreateTradingCase} allowIfHasRole={Role.ServicingTrading} />
                <PrivateRoute path="/carrier/:carrierID?" component={Carrier} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/carrier-requests" component={CarrierRequests} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/comparison-generator/:policyID?" component={ComparisonGenerator} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/contact" exact component={ContactSearch} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/client" exact component={ClientSearch} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/client/:ClientID" component={ClientDetails} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/create-client" component={CreateClient} allowIfHasAnyRole={[Role.GenericAdmin, Role.UWSupportSr]} />
                <PrivateRoute path="/death-claims" component={DeathClaims} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/document-management" component={DocumentManagement} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/decrypt" component={Decrypt} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/external-le-queue" component={ExternalLEQueue} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/fax-inbox" component={FaxInbox} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/client-policy-upload-queue" component={ClientPolicyUploadQueue} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/grace-queue" component={GraceQueue} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/insured/:insuredID" exact component={Insured} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/insured" component={InsuredSearch} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/insureds-near-maturity" component={InsuredsNearMaturity} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/longevity-Analytics" component={LongevityAnalytics} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute
                    path="/longevity-analytics-user"
                    exact
                    component={LongevityAnalyticsUserSearch}
                    allowIfHasRole={Role.ServicingUser}
                />
                <PrivateRoute
                    path="/longevity-analytics-user/:authID"
                    exact
                    component={LongevityAnalyticsUser}
                    allowIfHasRole={Role.ServicingUser}
                />
                <PrivateRoute
                    path="/machine-user"
                    component={MachineUser}
                    allowIfHasAnyRole={[Role.GenericAdmin, Role.ServicingPolicyManager]}
                />
                <PrivateRoute path="/mail-merge" component={MailMerge} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/mass-upload" exact component={MassUpload} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/mass-upload/:sessionID" component={MassUploadDetails} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/medrec-queue" component={MedrecQueue} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/policy/:policyID" exact component={Policy} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/policy/:policyID/grace-event/:graceID" exact component={Policy} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/policy" component={PolicySearch} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/policy-copy" component={PolicyCopy} allowIfHasRole={Role.ServicingAdmin} />
                <PrivateRoute path="/policy-opt-queue" component={OptQueue} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/policy-queue" component={PolicyQueue} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/policy-valuation" component={PolicyValuation} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/portfolio/:portfolioID" exact component={Portfolio} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/portfolio" exact component={PortfolioSearch} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/portfolio-upload" exact component={PortfolioUpload} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/payment-holds" exact component={PaymentHolds} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/product-library" exact component={ProductLibrary} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute
                    path="/product-library/:productFormNumberID"
                    exact
                    component={ProductLibrary}
                    allowIfHasRole={Role.ServicingUser}
                />
                <PrivateRoute path="/report-generator" component={ReportGenerator} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/secure-file-transfer" component={SecureFileTransfer} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/special-auth-files" component={SpecialAuthFiles} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/term-whole-life" component={TermWholeLife} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/tracking-queue" component={TrackingQueue} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/tracking/:insuredID" component={TrackingPage} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/trading-company" exact component={TradingCompanyList} allowIfHasRole={Role.ServicingTrading} />
                <PrivateRoute path="/trading-company/:companyID" component={TradingCompany} allowIfHasRole={Role.ServicingTrading} />
                <PrivateRoute path="/trading-queue" component={TradingQueue} allowIfHasRole={Role.ServicingTrading} />
                <PrivateRoute path="/user" exact component={UserSearch} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/user/:authID" exact component={User} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/user-permissions" exact component={UserPermissions} allowIfHasRole={Role.GenericAdmin} />
                <PrivateRoute path="/validation-queue" component={ValidationQueue} allowIfHasRole={Role.ServicingUser} />
                <PrivateRoute path="/wire-instructions" component={WireInstructions} allowIfHasRole={Role.ServicingUser} />
                <Redirect to="/policy" />
            </Switch>
        </Suspense>
    );
};

export default AppRoutes;
